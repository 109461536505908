
import VueQr from "vue-qr";
import { BPopover } from "bootstrap-vue";

const qrConfig = {
  components: {
    BPopover,
  },
  data: {
    scale: 1,
  },
  colorDark: "#212529",
  colorLight: "#ffffff",
  margin: 50,
  size: 1200,
  timing: {
    scale: 1,
    protectors: false,
  },
  alignment: {
    scale: 1,
    protectors: false,
  },
  cornerAlignment: {
    scale: 1,
    protectors: true,
  },
};

export default {
  components: {
    VueQr,
  },
  data() {
    return {
      url: window.location.origin,
      host: window.location.host,
      qrConfig: qrConfig,
    };
  },
  methods: {
    printURL: function () {
      console.log("router troubleshoot button");
      this.$route.name === "Home";
    },
  },
};
