
// @ is an alias to /src
import { mapGetters } from "vuex";

export default {
  name: "kioskOverlay",
  data() {
    return {
      panels: [1, 2, 3, 4, 5, 6, 7, 8],
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      getBreakpoints: "getBreakpoints",
    }),
  },
  methods: {},
};
