const colors = {
  orange: "#dd5e4d" /* fail on white 3.63 contrast */ /* old #f15647 */,
  Health: "#dd5e4d" /* fail on white 3.63 contrast */ /* old #f15647 */,
  Vida: "#dd5e4d" /* fail on white 3.63 contrast */ /* old #f15647 */,
  green: "#5d985d" /* fail on white 3.43 contrast */ /* old #83a44e */,
  lime: "#5d985d" /* fail on white 3.43 contrast */ /* old #83a44e */,
  Food: "#5d985d" /* fail on white 3.43 contrast */ /* old #83a44e */,
  Ag: "#5d985d" /* fail on white 3.43 contrast */ /* old #83a44e */,
  Terra: "#5d985d" /* fail on white 3.43 contrast */ /* old #83a44e */,
  skyBlue: "#2e9ea4" /* fail on white 3.20 contrast */ /* old #37a0a5 */,
  Water: "#2e9ea4" /* fail on white 3.20 contrast */ /* old #37a0a5 */,
  Hydro: "#2e9ea4" /* fail on white 3.20 contrast */ /* old #37a0a5 */,
  red: "#aa1d40" /* pass on white 7.09 contrast */ /* old #a6093d */,
  Community: "#aa1d40" /* pass on white 7.09 contrast */ /* old #a6093d */,
  blue: "#1f2759" /* pass on white 14.0 contrast */ /* old #00205b */,
  Environment: "#1f2759" /* pass on white 14.0 contrast */ /* old #00205b */,
  yellow: "#a78f31" /* pass on white 3.17 contrast */ /* old #dfae65 */,
  Energy: "#a78f31" /* fail on white 3.17 contrast */ /* old #dfae65 */,
  purple: "#824783" /* pass on white 6.60 contrast */,
  charcoal: "#323e48" /* pass on white 10.9 contrast */,
  Institutional: "#323e48" /* pass on white 10.9 contrast */,
  Stories: "#323e48" /* pass on white 10.9 contrast */,
  slate: "#505b65" /* pass on white 6.94 contrast */,
  secondary: "#323e48" /* pass on white 6.94 contrast */,
  Unkown: "#505b65" /* pass on white 6.94 contrast */,
  white: "#ffffff" /* fail on white 00.0 contrast */,
  black: "#000000" /* pass on white 21.0 contrast */,
  "map-dark": "#232227" /* pass on white 15.8 contrast */,
  "map-light": "#3f3f41" /* pass on white 10.5 contrast */,
  carbon: "#232227",
};

export default colors;
