var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-xxl-3"},[_c('h2',{staticClass:"text-center pb-1 pb-xxl-3",class:{
      'text-dark': 'light' === _vm.currentMap.background,
      'text-light': 'dark' === _vm.currentMap.background,
    }},[_vm._v(" "+_vm._s(_vm.$t(`baseMaps.mapType`))+" ")]),_c('div',{staticClass:"btn-group"},_vm._l((_vm.baseMaps),function(map){return _c('button',{key:map.id,staticClass:"btn btn-fade",class:{
        'btn-white': map.id === _vm.currentMap.id,
        'btn-secondary': map.id !== _vm.currentMap.id,
      },on:{"click":function($event){return _vm.setMap(map.id)}}},[_vm._v(" "+_vm._s(_vm.$t(`baseMaps.${map.id}`))+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }