var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"h2 text-white text-center mb-xxl-3 d-none d-xxl-block"},[_vm._v(" "+_vm._s(_vm.$t(`SortBy`))+" ")]),_c('div',{staticClass:"d-flex",class:{
      'btn-group': true,
      'btn-group-vertical': false,
    },attrs:{"role":"group","aria-label":"Stories By Title and Name Toggle Buttons"}},[_c('button',{staticClass:"btn btn-fade px-0",class:{
        'btn-white': _vm.sortStoriesBy === 'en-StoryTitle',
        'btn-secondary': _vm.sortStoriesBy !== 'en-StoryTitle',
      },attrs:{"type":"button","aria-label":_vm.$t(`SortByTitle`)},on:{"click":function($event){return _vm.setSortStoriesBy('en-StoryTitle')}}},[_vm._v(" "+_vm._s(_vm.$t(`SortByTitle`))+" ")]),_c('button',{staticClass:"btn btn-fade px-0",class:{
        'btn-white': _vm.sortStoriesBy === 'Last Name',
        'btn-secondary': _vm.sortStoriesBy !== 'Last Name',
      },attrs:{"type":"button","aria-label":_vm.$t(`SortByPerson`)},on:{"click":function($event){return _vm.setSortStoriesBy('Last Name')}}},[_vm._v(" "+_vm._s(_vm.$t(`SortByPerson`))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }