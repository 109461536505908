var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"h2 text-white text-center mb-xxl-3 d-none d-xxl-block"},[_vm._v(" Language ")]),_c('div',{staticClass:"d-flex",class:{
      'btn-group': true,
      'btn-group-vertical': false,
    },attrs:{"role":"group","aria-label":"Language Toggle Buttons"}},_vm._l((_vm.languages),function(language){return _c('button',{key:language.locale,staticClass:"btn btn-fade px-0",class:{
        'btn-white': _vm.$root.$i18n.locale === language.locale,
        'btn-secondary': _vm.$root.$i18n.locale !== language.locale,
      },attrs:{"type":"button","aria-label":language.language},on:{"click":function($event){return _vm.setLanguage(language.locale)}}},[_vm._v(" "+_vm._s(language.language)+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }